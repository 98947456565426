














import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, Action, State } from 'vuex-class'
import { Route } from 'vue-router'
import { mapActions, mapState } from 'vuex'

@Component({
  methods: {
    ...mapActions([
      'releaseMiniapp', 'fetchMiniProgram'
    ])
  }
})

export default class Deploy extends Vue {
  private releasing: boolean = false
  public loading: boolean = true
  private miniProgram: any = null
  releaseMiniapp!: (projectId: string) => any
  fetchMiniProgram!: (projectId: string) => any

  async mounted () {
    try {
      const data = await this.fetchMiniProgram(this.$route.params.id)
      this.miniProgram = data
    } catch (err) {
      this.$toast.error(err.message)
    } finally {
      this.loading = false
    }
  }

  tapReleaseMiniapp (project: any) {
    this.releasing = true
    this.releaseMiniapp(this.miniProgram.project.id).then(() => {
      project.releaseDate = this.$moment().format("YYYY-MM-DD HH:mm:ss")
      this.$toast.success('Release successfully', {decuration: 2000})
      this.$router.push({ name: 'mini-programs'})
      this.releasing = false
    }).catch((err: any) => {
      this.releasing = false
      this.$toast.error(err.errmsg, {decuration: 2000})
    })
  }
}

